export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard-analytics',
    permissions: ['dashboard'],
  },

  {
    header: 'System',
    permissions: [
      'championships',
      'leagues',
      'matches',
      'players',
      'teams',
      'stadiums',
      'formations',
      'scorers',
      'referees',
      'reports',
    ],
  },
  {
    title: 'Championships',
    icon: 'CircleIcon',
    route: 'championships',
    permissions: ['championships'],
  },
  {
    title: 'Leagues',
    icon: 'CircleIcon',
    route: 'leagues',
    permissions: ['leagues'],
  },
  {
    title: 'Matches',
    icon: 'CircleIcon',
    route: 'matches',
    permissions: ['matches'],
  },
  // {
  //   title: 'Competitions',
  //   icon: 'CircleIcon',
  //   route: 'competitions',
  //   permissions: ['competitions'],
  // },
  {
    title: 'Players',
    icon: 'CircleIcon',
    route: 'players',
    permissions: ['players'],
  },
  {
    title: 'Teams',
    icon: 'CircleIcon',
    route: 'teams',
    permissions: ['teams'],
  },
  {
    title: 'Stadiums',
    icon: 'CircleIcon',
    route: 'stadiums',
    permissions: ['stadiums'],
  },
  {
    title: 'scorers',
    icon: 'CircleIcon',
    route: 'scorers',
    permissions: ['scorers'],
  },
  {
    title: 'formations',
    icon: 'CircleIcon',
    route: 'formations',
    permissions: ['formations'],
  },
  {
    title: 'referees',
    icon: 'CircleIcon',
    route: 'referees',
    permissions: ['referees'],
  },
  {
    title: 'Banners',
    icon: 'CircleIcon',
    route: 'banners',
    permissions: ['banner'],
  },
  {
    title: 'Usage terms',
    icon: 'CircleIcon',
    route: 'usage-terms',
    permissions: ['usage-term'],
  },
  {
    title: 'reports',
    icon: 'CircleIcon',
    route: 'reports',
    permissions: ['reports'],
  },
  {
    header: 'Prizes',
    permissions: ['prize'],
  },
  {
    title: 'Prizes Periods',
    icon: 'CircleIcon',
    route: 'prizes-periods',
    permissions: ['prizes-period'],
  },
  {
    title: 'Prizes',
    icon: 'CircleIcon',
    route: 'prizes',
    permissions: ['prize'],
  },
  {
    header: 'News & Analysts',
    permissions: ['news', 'analysts'],
  },
  {
    title: 'News',
    icon: 'CircleIcon',
    route: 'news',
    permissions: ['news'],
  },
  {
    title: 'News Categories',
    icon: 'CircleIcon',
    route: 'news-categories',
    permissions: ['news_categories'],
  },
  {
    title: 'analytics',
    icon: 'CircleIcon',
    route: 'analytics',
    permissions: ['analytics'],
  },
  {
    title: 'analysts',
    icon: 'CircleIcon',
    route: 'analysts',
    permissions: ['analysts'],
  },
  {
    title: 'Tags',
    icon: 'CircleIcon',
    route: 'tags',
    permissions: ['tags'],
  },
  {
    title: 'writers',
    icon: 'CircleIcon',
    route: 'writers',
    permissions: ['writers'],
  },
  {
    header: 'Notifications',
    permissions: ['pictures', 'short_videos', 'youtube-channels'],
  },
  {
    title: 'Faster Notifications',
    icon: 'CircleIcon',
    route: 'faster-notifications',
    permissions: ['analytics'],
  },
  {
    title: 'Update Match Status',
    icon: 'CircleIcon',
    route: 'urgent-match-updates',
    permissions: ['analytics'],
  },
  {
    title: 'Center Notifications',
    icon: 'CircleIcon',
    route: 'center-notifications',
    permissions: ['analytics'],
  },
  {
    title: 'Notification Logs',
    icon: 'CircleIcon',
    route: 'notification-logs',
    permissions: ['analytics'],
  },
  {
    header: 'Manage Notifications',
    permissions: ['analytics'],
  },
  {
    title: 'Manage',
    icon: 'CircleIcon',
    route: 'manage-notifications',
    permissions: ['analytics'],
  },
  {
    title: 'Match Events',
    icon: 'CircleIcon',
    route: 'manage-match-events-notifications',
    permissions: ['analytics'],
  },
  {
    header: 'Logs',
    permissions: ['analytics'],
  },
  {
    title: 'Customer Expectations',
    icon: 'CircleIcon',
    route: 'customer-expectations-logs',
    permissions: ['analytics'],
  },
  {
    header: 'Pictures & Videos',
    permissions: ['pictures', 'short_videos', 'youtube-channels'],
  },
  {
    title: 'Pictures',
    icon: 'CircleIcon',
    route: 'pictures',
    permissions: ['pictures'],
  },
  {
    title: 'Short Videos',
    icon: 'CircleIcon',
    route: 'short-videos',
    permissions: ['short_videos'],
  },
  {
    title: 'Youtube Channels',
    icon: 'CircleIcon',
    route: 'youtube-channels',
    permissions: ['youtube-channels'],
  },
  {
    header: 'UpdateData',
    permissions: ['update-data'],
  },
  {
    title: 'UpdateData',
    icon: 'CircleIcon',
    route: 'update-data',
    permissions: ['update-data'],
  },
  {
    header: 'Roles & Users',
    permissions: ['users', 'roles', 'customers', 'contact_us'],
  },
  {
    title: 'Roles',
    icon: 'UsersIcon',
    route: 'roles',
    permissions: ['roles'],
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    route: 'users',
    permissions: ['users'],
  },
  {
    title: 'customers',
    icon: 'UserIcon',
    route: 'customers',
    permissions: ['customers'],
  },
  {
    title: 'Customer Points Center',
    icon: 'CircleIcon',
    route: 'customer-points-center',
    permissions: ['customers'],
  },
  {
    title: 'Contact Us',
    icon: 'UserIcon',
    route: 'contact-uses',
    permissions: ['contact_us'],
  },
  {
    header: 'Countries',
    permissions: ['countries'],
  },
  {
    title: 'Countries',
    icon: 'MapIcon',
    route: 'countries',
    permissions: ['countries'],
  },

  {
    header: 'Settings',
    permissions: ['settings'],
  },
  {
    title: 'Settings',
    icon: 'GearIcon',
    route: 'settings',
    permissions: ['settings'],
  },
  {
    title: 'Splash Screen Setting',
    icon: 'GearIcon',
    route: 'splash-screen-settings',
    permissions: ['splash-screen-setting'],
  },
]
